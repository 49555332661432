/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTheme } from "./ThemeProvider";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import { Text, TextVariant } from "./typography";
import { ITheme, breakpoints } from "../style/theme";

function Footer() {
  const theme = useTheme() as ITheme;

  const footerStyles = css({
    backgroundColor: theme.colors.oldLace,
    padding: "12.5px 0px",
  });

  const colStyle = css({
    display: "flex",
    justifyContent: "flex-end",
    [`@media screen and (max-width: ${breakpoints.small}px)`]: {
      justifyContent: "flex-start",
    },
  });
  const colStyleStart = css({
    display: "flex",
    justifyContent: "flex-start",
    [`@media screen and (max-width: ${breakpoints.small}px)`]: {
      display: "none",
    },
  });

  const labelStyle = css({
    marginRight: 25,
    textTransform: "uppercase",
    color: theme.colors.shipGray,
  });

  const linkStyle = css({
    marginRight: 25,
    "&.active .link-text": {
      borderBottom: `1px solid ${theme.colors.saddleBrown}`,
    },
  });
  const contactLinkStyle = css({
    marginRight: 25,
    "&.active .link-text": {
      borderBottom: `1px solid ${theme.colors.saddleBrown}`,
    },
    [`@media screen and (max-width: ${breakpoints.small}px)`]: {
      display: "none",
    },
  });
  const contactLinkStyleMobile = css({
    marginRight: 25,
    display: "none",
    "&.active .link-text": {
      borderBottom: `1px solid ${theme.colors.saddleBrown}`,
    },
    [`@media screen and (max-width: ${breakpoints.small}px)`]: {
      display: "block",
    },
  });
  const linkTextStyle = css({
    textTransform: "uppercase",
    color: theme.colors.saddleBrown,
  });

  const mailToAddress = "mailto:rich@careteamvitals.onmicrosoft.com";

  return (
    <footer css={footerStyles}>
      <Container>
        <Row justify="between">
          <Col css={colStyleStart}>
            <a href={mailToAddress} css={contactLinkStyle}>
              <Text
                css={linkTextStyle}
                variant={TextVariant.small_C}
                className="link-text"
              >
                Contact
              </Text>
            </a>
          </Col>
          <Col css={colStyle}>
            <Text css={labelStyle} variant={TextVariant.small_D}>
              Policies:
            </Text>
            <Link to="/privacy/" activeClassName={"active"} css={linkStyle}>
              <Text
                css={linkTextStyle}
                variant={TextVariant.small_C}
                className="link-text"
              >
                Privacy
              </Text>
            </Link>
            <Link
              to="/terms-of-service/"
              activeClassName={"active"}
              css={linkStyle}
            >
              <Text
                css={linkTextStyle}
                variant={TextVariant.small_C}
                className="link-text"
              >
                Terms
              </Text>
            </Link>
            <a href={mailToAddress} css={contactLinkStyleMobile}>
              <Text
                css={linkTextStyle}
                variant={TextVariant.small_C}
                className="link-text"
              >
                Contact
              </Text>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
