/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTheme } from "./ThemeProvider";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-grid-system";
import { Text, TextVariant } from "./typography";
import { ITheme } from "../style/theme";

function Header() {
  const theme = useTheme() as ITheme;
  const headerStyles = css({
    height: 65,
    backgroundColor: theme.colors.alabaster,
  });
  const colStyle = css({ display: "flex", justifyContent: "flex-end" });
  const linkStyles = css({
    margin: 25,
    marginBottom: 0,
  });
  const linkTextStyles = css({
    textTransform: "uppercase",
    paddingTop: 25,
    color: theme.colors.saddleBrown,
  });
  const activeLinkStyle = {
    borderBottom: `1px solid ${theme.colors.saddleBrown}`,
  };

  return (
    <header css={headerStyles}>
      <Container>
        <Row>
          <Col css={colStyle}>
            <Link to="/beta/" css={linkStyles} activeStyle={activeLinkStyle}>
              <Text css={linkTextStyles} variant={TextVariant.small_DD}>
                Beta
              </Text>
            </Link>
            <Link to="/about/" css={linkStyles} activeStyle={activeLinkStyle}>
              <Text css={linkTextStyles} variant={TextVariant.small_DD}>
                About
              </Text>
            </Link>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
