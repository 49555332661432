import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import "../style/global.css";
import { ThemeProvider } from "./ThemeProvider";
import { setConfiguration, ScreenClassProvider } from "react-grid-system";
import { theme, breakpoints } from "../style/theme";

function Layout(props) {
  React.useEffect(() => {
    setConfiguration({
      containerWidths: [
        breakpoints.xSmall,
        breakpoints.small,
        breakpoints.medium,
        breakpoints.mediumLarge,
        breakpoints.large,
      ],
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ScreenClassProvider>
        <Header />
        {props.children}
        <Footer />
      </ScreenClassProvider>
    </ThemeProvider>
  );
}

export default Layout;
