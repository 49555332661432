const React = require("react");
const Layout = require("./src/components/layout").default;
const PropTypes = require("prop-types");

const LayoutWrapper = ({ element, props }) => {
  if (typeof window != "undefined") {
    if (window.location.pathname !== "/") {
      return <Layout {...props}>{element}</Layout>;
    }
  }
};

LayoutWrapper.propTypes = {
  element: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};

export const wrapPageElement = LayoutWrapper;
