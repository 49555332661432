import { TextVariant } from "../components/typography";

export interface ITheme {
  colors: {
    alabaster?: string;
    oldLace?: string;
    gamboge?: string;
    shipGray?: string;
    saddleBrown?: string;
    fountainBlue?: string;
    silverSand?: string;
    minsk?: string;
  };
  semanticColors: {
    [key: string]: string;
  };
  fonts: {
    [key in TextVariant]: {
      fontFamily: string;
      fontSize: string;
      fontStyle: string;
      fontWeight: number;
      lineHeight: string;
      letterSpacing?: string;
    };
  };
}
// 540, 740, 960, 1180, 1540
export const breakpoints = {
  xSmall: 540,
  small: 740,
  medium: 960,
  mediumLarge: 1180,
  large: 1540,
  xLarge: 1920,
};

export const imageSizes = {
  small: 300,
  small_mobile: 320,
  large: 775,
  large_mobile: 320,
};

const colors = {
  alabaster: "#FCFCFC",
  oldLace: "#FCF4E7",
  gamboge: "#E2950C",
  shipGray: "#4F4F4F",
  saddleBrown: "#87420D",
  fountainBlue: "#5BBECD",
  silverSand: "#C4C4C4",
  minsk: "#342984",
};

export const theme: ITheme = {
  colors: {
    ...colors,
  },
  semanticColors: {},
  fonts: {
    small_C: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
    },
    small_D: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "26px",
    },
    small_DD: {
      fontFamily: "Lato",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
    },
    medium_D: {
      fontFamily: "Lato",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
      letterSpacing: "0em",
    },
    large_C: {
      fontFamily: "Lato",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "43px",
      letterSpacing: "0em",
    },
    large_D: {
      fontFamily: "Lato",
      fontSize: "40px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "50px",
      letterSpacing: "0em",
    },
    xLarge_C: {
      fontFamily: "Lato",
      fontSize: "72px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "86px",
      letterSpacing: "0em",
    },
  },
};
