import * as React from "react";
import { theme, ITheme } from "../style/theme";

interface IThemeProviderProps {
  children: JSX.Element | JSX.Element[];
  theme: ITheme;
}

let Theme = React.createContext<ITheme>(theme);

const ThemeProvider = (props: IThemeProviderProps): JSX.Element => {
  Theme = React.createContext<ITheme>(theme);
  return <Theme.Provider value={props.theme}>{props.children}</Theme.Provider>;
};

const useTheme = (): ITheme => {
  if (Theme) {
    return React.useContext(Theme);
  } else console.error("Theme has not been initialized");
};

export { ThemeProvider, Theme, useTheme };
