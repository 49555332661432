import * as React from "react";
import { ITheme } from "../style/theme";
import { useTheme } from "./ThemeProvider";

export enum TextVariant {
  small_C = "small_C",
  small_D = "small_D",
  small_DD = "small_DD",
  medium_D = "medium_D",
  large_C = "large_C",
  large_D = "large_D",
  xLarge_C = "xLarge_C",
}

interface IText extends React.HTMLProps<HTMLParagraphElement & HTMLHRElement> {
  variant: TextVariant;
  children: React.ReactChild | React.ReactChildren | React.ReactNode | string;
}

function Text(props: IText): JSX.Element {
  let ComponentArg1 = null;
  let ComponentArg2 = null;
  const theme = useTheme() as ITheme;

  if (theme && theme.fonts) {
    switch (props.variant) {
      case TextVariant.small_C:
        ComponentArg1 = "p";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      case TextVariant.small_D:
        ComponentArg1 = "p";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      case TextVariant.small_DD:
        ComponentArg1 = "p";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      case TextVariant.medium_D:
        ComponentArg1 = "p";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      case TextVariant.large_C:
        ComponentArg1 = "h3";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      case TextVariant.large_D:
        ComponentArg1 = "h2";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      case TextVariant.xLarge_C:
        ComponentArg1 = "h1";
        ComponentArg2 = { style: theme.fonts[props.variant], ...props };
        break;
      default:
        console.error("component failed to initialize");
    }
  }

  if (ComponentArg1 && ComponentArg2) {
    return React.createElement(ComponentArg1, ComponentArg2, props.children);
  } else return null;
}

export { Text };
